export default [
    {
        key: "client_name",
        label: "Client Name",
        visible: true
    },
    {
        key: "address",
        label: "Mailing Address",
        visible: true
    },
    {
        key: "program",
        label: "Program",
        visible: true
    },
    {
        key: "user_name",
        label: "Request by",
        visible: true
    },
    {
        key: "letter_format",
        label: "Format",
        visible: true
    },
    {
        key: "letter_print",
        label: "Letter",
        visible: true
    },
    {
        key: "letter_sent",
        label: "Sent",
        visible: true
    }

]
<template>
  <div>
    <filter-slot
      :filterPrincipal="filterPrincipal"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refPendingList"
          small
          :items="getAllData"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <div>
              <span class="text-primary">{{ data.item.client_name }}</span>
              <br />
              <span>{{ data.item.account }}</span>
            </div>
          </template>

          <template #cell(user_name)="data">
            <div>
              <span>{{ data.item.user_name }}</span>
              <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>

          <template #cell(letter_format)="data">
            <span v-if="data.item.letter">{{ data.item.letter }}</span>
            <span v-else>Statement</span>
          </template>

          <template #cell(letter_print)="data">
            <div v-if="data.item.letter_client_type_id !== 15">
              <div v-if="data.item.letter">
                <div v-if="data.item.program_id == 3">
                  <feather-icon
                    icon="PrinterIcon"
                    class="text-primary"
                    size="20"
                    @click="openPdf(data.item, 'pdfletterclient')"
                    style="cursor: pointer"
                    target="_blank"
                  >
                  </feather-icon>
                </div>
                <div v-if="data.item.program_id == 2">
                  <feather-icon
                    icon="PrinterIcon"
                    class="text-primary"
                    size="20"
                    target="_blank"
                    style="cursor: pointer"
                    @click="openPdf(data.item, 'pdfletterclientbc')"
                  >
                  </feather-icon>
                </div>
              </div>
              <div v-else>
                <a :href="data.item.route_file" target="_blank">
                  <feather-icon
                    icon="PrinterIcon"
                    class="text-primary"
                    size="20"
                    target="_blank"
                    @click="openPdfStatement(data.item.id)"
                  >
                  </feather-icon>
                </a>
              </div>
            </div>
            <div
              style="min-width: 150px"
              class="py-1"
              v-if="data.item.letter_client_type_id === 15"
            >
              <b-row>
                <b-col
                  cols="4"
                  v-for="(element, index) in data.item.url"
                  :key="index"
                >
                  <a target="_blank" :href="element.url">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                      @click="openClientLetterCi(data.item.id, index)"
                    >
                      <amg-icon
                        class="cursor-pointer bigger text-danger"
                        icon="FilePdfIcon"
                      />
                      <b-badge
                        :variant="
                          element.open ? 'light-primary' : 'light-warning'
                        "
                        style="margin-top: 5px"
                      >
                        {{ element.name }}
                      </b-badge>
                    </div>
                  </a>
                </b-col>
              </b-row>
            </div>
          </template>
          <template #cell(program)="data">
            <span>{{
              data.item.letter_client_type_id == 15
                ? "Debt Solution"
                : data.item.program
            }}</span>
          </template>
          <template #cell(letter_sent)="data">
            <div v-if="data.item.letter_client_type_id !== 15">
              <div v-if="data.item.letter">
                <feather-icon
                  icon="CheckIcon"
                  class="text-secondary"
                  size="20"
                  v-if="data.item.open == 0"
                ></feather-icon>
                <feather-icon
                  icon="CheckIcon"
                  class="text-success cursor-pointer"
                  size="20"
                  v-if="data.item.open == 1"
                  @click="sentLetter(data.item.id)"
                ></feather-icon>
              </div>
              <div v-else>
                <feather-icon
                  icon="CheckIcon"
                  class="text-secondary"
                  size="20"
                  v-if="data.item.open == 0"
                ></feather-icon>
                <feather-icon
                  icon="CheckIcon"
                  class="text-success cursor-pointer"
                  size="20"
                  v-if="data.item.open == 1"
                  @click="sentStatement(data.item.id)"
                ></feather-icon>
              </div>
            </div>
            <div v-if="data.item.letter_client_type_id === 15">
              <b-button
                pill
                variant="success"
                @click="sentLetter(data.item.id)"
                :disabled="validateAllPdfOpen(data.item.url)"
              >
                <feather-icon icon="SendIcon" style="margin-right: 4px"></feather-icon>
                Send
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>
<script>
import filters from "../data/filters.pending.data";
import fields from "../data/fields.pending.data";
import LettersToClientsService from "../../service/lettersToClients.service";
import { mapMutations, mapGetters } from "vuex";
import indexVue from "@/views/business/views/report/index.vue";
export default {
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "created_at",
      sortDesc: true,
      fields: fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
    };
  },
  created() {
    this.getPrograms();
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },

    isLetterClient() {
      return this.$route.meta.client;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async pdfletterclient(data) {
      if (this.isLetterClient != 0) {
        try {
          const params = {
            accountid: data.client_account_id,
            type: data.letter_client_type_id,
          };
          this.addPreloader();
          const response = await LettersToClientsService.openPdfLetterClient(
            params
          );
          if (response.status == 200) {
            this.forceFileDownload(response.data, "document.pdf", "blank");
          }
          this.removePreloader();
        } catch (exception) {}
      }
    },
    async pdfLetterClientBc(data) {
      try {
        const params = {
          accountid: data.client_account_id,
          type: data.letter_client_type_id,
        };
        this.addPreloader();
        const response = await LettersToClientsService.openPdfLetterClientBc(
          params
        );
        if (response.status == 200) {
          this.forceFileDownload(response.data, "document.pdf", "blank");
        }
        this.removePreloader();
      } catch (exception) {}
    },
    ...mapMutations({
      countLettertoClient: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    refresh() {
      this.$refs.refPendingList.refresh();
    },
    async refreshCount() {
      const response = await LettersToClientsService.contLetterClient();
      const item = response.data[0];
      const payload = {
        routeName: "correspondence-letters-to-clients",
        tag: item.cont,
      };
      await this.countLettertoClient(payload);
    },
    async openPdfStatement(id) {
      try {
        const response = await LettersToClientsService.openStatementClientPdf({
          id,
        });
        if (response.status == 200) {
          this.refresh();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async openPdf(data, opcionPdf) {
      try {
        if (opcionPdf == "pdfletterclient") {
          await this.pdfletterclient(data);
        } else if (opcionPdf == "pdfletterclientbc") {
          await this.pdfLetterClientBc(data);
        }
        const response = await LettersToClientsService.openLetterClientPdf({
          id: data.id,
        });

        if (response.status == 200) {
          this.refresh();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async sentLetter(id) {
      const swal = await this.showConfirmSwal();
      if (swal.isConfirmed) {
        this.addPreloader();
        try {
          const response = await LettersToClientsService.sentLetterClient({
            id: id,
            created_by: this.currentUser.user_id,
          });
          if (response.status == 200) {
            this.showSuccessSwal("OPERATION SUCCESSFULLY");
            this.removePreloader();
            this.refresh();
            const response = await LettersToClientsService.contLetterClient();
          }
        } catch (error) {
          console.error(error);
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
    async sentStatement(id) {
      const swal = await this.showConfirmSwal();
      if (swal.isConfirmed) {
        this.addPreloader();
        try {
          const response = await LettersToClientsService.sentStatementClient({
            id,
          });
          if (response.status == 200) {
            this.showSuccessSwal("OPERATION SUCCESSFULLY");
            this.removePreloader();
            this.refresh();
            const response = await LettersToClientsService.contLetterClient();
          }
        } catch (error) {
          console.error(error);
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
    async getPrograms() {
      const response = await LettersToClientsService.getProgramsAdvanceSearch();
      let firstOption = {
        value: "All",
        id: 0,
      };
      let newData = response.map((item) => ({
        value: item.value,
        id: item.id,
      }));
      newData.unshift(firstOption);
      this.filter[2].options = newData;
    },
    async getAllData() {
      try {
        const params = {
          status: 1,
          orderby: 6,
          order: "desc",
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          program: this.filter[2].model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          client_sp: this.isLetterClient,
        };
        const response = await LettersToClientsService.getDataLettersToClients(
          params
        );
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        this.refreshCount();
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async openClientLetterCi(letter_id, index) {
      try {
        const params = {
          id: letter_id,
          index: index,
        };

        const response = await LettersToClientsService.openClientLetterCi(
          params
        );

        if (response.status == 200) {
          this.refresh();
        }
      } catch (error) {
        console.error(error);
      }
    },

    validateAllPdfOpen(items) {
      return !items.every(function (item) {
        return item.open === true;
      });
    },
  },
};
</script>

<style scoped></style>

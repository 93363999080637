<template>
	<div>
		<header-slot />
		<b-nav class="m-0" card-header pills>
			<b-nav-item
				:to="{ name: 'report-bussiness' }"
				exact
				exact-active-class="active"
				>Index</b-nav-item
			>
		</b-nav>
		<b-card class="border-top-primary border-3 border-table-radius px-0">
			<ReportsTable />
		</b-card>
	</div>
</template>

<script>
import ReportsTable from "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue"

export default {
	components: {
		ReportsTable,
	},
}
</script>
